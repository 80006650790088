import "./App.scss";
import React from "react";
import LandingPage from "./LandingPage/LandingPage";
import Footer from "./Footer/Footer";
import ProductFeatures from "./ProductFeatures/ProductFeatures";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

class App extends React.Component {
  // componentDidMount() {
  //   console.log("Done");
  //   (function () {
  //     var reb2b = (window.reb2b = window.reb2b || []);
  //     if (reb2b.invoked) return;
  //     reb2b.invoked = true;
  //     reb2b.methods = ["identify", "collect"];
  //     reb2b.factory = function (method) {
  //       return function () {
  //         var args = Array.prototype.slice.call(arguments);
  //         args.unshift(method);
  //         reb2b.push(args);
  //         return reb2b;
  //       };
  //     };
  //     for (var i = 0; i < reb2b.methods.length; i++) {
  //       var key = reb2b.methods[i];
  //       reb2b[key] = reb2b.factory(key);
  //     }
  //     reb2b.load = function (key) {
  //       var script = document.createElement("script");
  //       script.type = "text/javascript";
  //       script.async = true;
  //       script.src =
  //         "https://s3-us-west-2.amazonaws.com/b2bjsstore/b/" +
  //         key +
  //         "/reb2b.js.gz";
  //       var first = document.getElementsByTagName("script")[0];
  //       first.parentNode.insertBefore(script, first);
  //     };
  //     reb2b.SNIPPET_VERSION = "1.0.1";
  //     reb2b.load("961Y0HXQ3DNG");
  //     console.log("Yes");
  //   })();
  // }
  render() {
    return (
      <Router>
        <Switch>
          <Route path="/features">
            <ProductFeatures />
          </Route>
          <Route path="/">
            <LandingPage />
          </Route>
        </Switch>
        <Footer />
      </Router>
    );
  }
}

export default App;
